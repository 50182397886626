import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useAxios } from "../../../services/http.service";
import { fetchTokenAndPostChatbot } from "../../../services/http.chatbot.service";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import notificationService from "../../../services/notification.service";
import ChatWaitingIcon from "../../Icons/ChatWaitingIcon";
import ChatLoaderIcon from "../../Icons/ChatLoaderIcon";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import QuerieIcon from "./QuerieIcon";
import useChatMessages from "../../../hooks/useChatMessages";
import { theme } from "../../../constant/Theme";
import CopyIcon from "../../Icons/CopyIcon";
import SendIcon from "../../Icons/sendIcon";
import useFileActions from "../../../hooks/useFileActions";
import LoaderIcon from "../../Icons/LoaderIcon";
import GetAppIcon from "@mui/icons-material/GetApp";
import ChatCopyIcon from "../../Icons/ChatCopyIcon";
import ChatDisabledIcon from "../../Icons/ChatDisabledIcon";

const QueriesForm = ({ chatValue, chatHistory, setChatHistory }) => {
  const { user } = useSelector((state) => state.reducer);

  const { isLoading, allChat: data } = useChatMessages();
  const [loader, setLoader] = useState(false);
  const queryClient = useQueryClient();
  const { post, setBearerToken, get } = useAxios();
  const formik = useFormik({
    initialValues: {
      title: "",
    },
    // validationSchema: {}, // You can define a Yup validation schema here
    onSubmit: async (values, { resetForm }) => {
      try {
        // Validate the form
        if (!values.title) {
          return;
        }
        if (!chatValue.caseId || !chatValue.category) {
          return;
        }

        setLoader(true);

        let fileData;

        const sourceNumberPerson =
          chatValue.category === "person" ? 1 : chatValue?.sources;

        if (chatValue.category === "medical" && chatValue?.type) {
          // Check if personsId exists for more specific logic
          if (chatValue?.personsId) {
            // Map the personData to fileUrl if the personsId matches
            fileData = chatValue?.personData
              ?.map((v) => {
                if (v.personsId === chatValue.personsId) {
                  return v.fileUrl; // Return the fileUrl if personsId matches
                }
                return null; // Return null for non-matching personsId
              })
              .filter((url) => url); // Filter out null or empty values
          } else {
            // If personsId is not provided, use the general fileUrl mapping
            fileData = chatValue?.personData?.map((v) => v?.fileUrl);
          }
        } else {
          // Fallback to chatHistory or chatValue.items
          fileData = chatValue?.items || chatHistory?.files;
        }

        // Fallback if no fileData is found
        if (!fileData || fileData.length === 0) {
          fileData = []; // Fallback to an empty array if no files are found
        }

        let witnessData;
        if (chatHistory?.person_id || chatValue?.personsId) {
          witnessData = chatValue?.personData?.find(
            (v) =>
              v.personsId === chatValue?.personsId || chatHistory?.person_id
          )?.witnessData;
        }
        // Log to check the final data
        let caseId = (chatValue.caseId || []).filter((item) => item !== "all");

        let SourceNo =
          sourceNumberPerson === 0 &&
          sourceNumberPerson !== undefined &&
          sourceNumberPerson !== ""
            ? sourceNumberPerson
            : chatHistory?.source_number
            ? chatHistory?.source_number
            : 5;
        const apiData = {
          question: values.title,
          file: fileData,
          thread_id: chatHistory ? chatHistory.thread_id : "",
          matter_id: caseId,
          witness: chatValue?.personsId || chatHistory?.person_id,
          // witnessData: chatValue?.witnessData || witnessData,
          roles:
            chatValue.category == "person"
              ? [chatValue?.type]
              : chatValue?.type,
          max_tokens: chatValue?.maxTokens || 1000,
          sources:
            sourceNumberPerson !== undefined && sourceNumberPerson !== ""
              ? sourceNumberPerson
              : parseInt(chatHistory?.source_number) || 4,
          assistant_id:
            user?.organizationData?.assistant_id ||
            "asst_SZJsPGkHhIqeqzBbkAYIXlQ2",
          organization: user?.organizationData?._id,
          api_key:
            user?.organizationData?.API_KEY ||
            "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
        };

        // Fetch external API
        const externalApiResponse = await fetchTokenAndPostChatbot(
          `/${chatValue.category}/retrieve`,
          apiData
        );

        // Check if the external API call was successful
        if (externalApiResponse.status === 200 && externalApiResponse.data) {
          if (externalApiResponse.data?.thread_id !== "") {
            const token = user?.userData?.token;
            setBearerToken(token);
            const data = {
              chatId: chatValue.items?.id,
              question: values.title,
              answer: externalApiResponse.data.answer,
              moduleName: chatValue?.category || chatHistory?.moduleName || "",
              title: externalApiResponse.data.file,
              type: chatValue?.type || chatHistory?.type || "",
              files: chatValue?.items || chatHistory?.files,
              assistant_id:
                user?.organizationData?.assistant_id ||
                "asst_SZJsPGkHhIqeqzBbkAYIXlQ2",
              person_id: chatValue?.personsId || chatHistory?.person_id || "",
              source_number:
                sourceNumberPerson || chatHistory?.source_number || 4,
              matter_id: chatValue.caseId,
              max_tokens: chatValue?.maxTokens,
              thread_id:
                externalApiResponse.data?.thread_id ||
                chatHistory?.thread_id ||
                "",
              page: externalApiResponse.data?.page,
              sources: externalApiResponse.data?.sources,
              token_usage: externalApiResponse.data?.token_usage,
            };

            // Post data to local API
            const response = await post(`chatbot`, data);

            if (response) {
              setLoader(false);
              setChatHistory(data);
              resetForm();
              queryClient.invalidateQueries("thread");
              // notificationService.success("Successfully generated response");
            } else {
              throw new Error(
                "Error: Failed to submit the data to the server."
              );
            }
          }
        } else {
          throw new Error("Error: External API call failed.");
        }
      } catch (error) {
        console.error("Submission error:", error);
        notificationService.error(
          error.message || "An error occurred during submission."
        );
        setLoader(false); // Ensure loader is disabled if an error occurs
      } finally {
        resetForm(); // Reset the form after submission or error
      }
    },
  });

  const [expanded, setExpanded] = React.useState([]); // Track multiple expanded panels
  const { handleFileAction, downloadLoader } = useFileActions();

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((item) => item !== panel)
    );
  };

  const chatContainerRef = useRef(null);
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      // setCopySuccess('Copied!');
    } catch (err) {
      // setCopySuccess('Failed to copy!');
    }
  };
  // Scroll to the bottom of the chat when data changes
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scroll({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth", // Ensure smooth scrolling
      });
    }
  }, [data, loader]);
  function formatParagraphForMarkdown(paragraph) {
    // Add a newline before numbered lists (e.g., "1. " or "2. ").
    let formattedParagraph = paragraph.replace(/(\d+)\.\s/g, "\n\n$1. ");

    // Ensure the paragraph doesn't insert extra newlines for dots or commas.
    formattedParagraph = formattedParagraph.replace(
      /(\d+\.\s[^.]*)(?=\d+\.\s|$)/g,
      "$1\n\n"
    );

    return formattedParagraph.trim();
  }
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");

  const handleCopy = (answer) => {
    copyToClipBoard(formatParagraphForMarkdown(answer)); // Your copy logic
    setTooltipText("Copied successfully!");

    // Reset tooltip message after a delay
    setTimeout(() => setTooltipText("Copy to clipboard"), 2000);
  };
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        height: "75vh",
        justifyContent: "space-between",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
          height: "81vh", // Adjust height for medium screens
        },
        [theme.breakpoints.down("sm")]: {
          height: "65vh", // Adjust height for small screens
        },
        "@media (min-width: 1920px)": {
          height: "80vh", // Adjust for 1920px and larger screens
        },
        "@media (min-width: 4480px)": {
          height: "90vh", // Adjust height for 4480px and larger screens
        },
      })}
    >
      <Box
        sx={{
          // height: "100%",
          flexGrow: 1, // Ensures this section grows and takes available space
          overflowY: "auto",
        }}
        ref={chatContainerRef}
      >
        {/* overflowY: "auto"  */}
        {data?.map((v, j) => (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center ",
                justifyContent: "end",
                gap: "10px",
                margin: "15px 0 15px 100px ",
                fontSize: "12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.primary?.placeholderColor,
                  // fontSize: "12px",
                  // fontWeight: "400",
                  fontFamily: theme?.typography?.fontFamily,
                  background: "#F5EEFF",
                  p: 1,
                  borderRadius: "4px",
                  // maxWidth: "80%",
                  ...theme?.typography?.bodyText,
                  // margin: "15px 0 15px 100px ",
                }}
              >
                {v.question}
              </Typography>

              <Typography
                sx={{
                  p: "7px",
                  backgroundColor: theme?.palette?.primary?.secondaryheading,
                  width: "38px",
                  height: "38px",
                  borderRadius: "50%",
                  color: "#2B2C40",
                  marginRight: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  ...theme?.typography?.inputPlaceHolder,
                }}
              >
                {`${user?.userData?.firstName?.charAt(
                  0
                )}${user?.userData?.lastName?.charAt(0)}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "5px",
                  backgroundColor: "#F4F8FF",
                  width: "100%",
                  paddingLeft: "7px",
                }}
              >
                <Box
                  component="img"
                  src="/images/logoChat.png"
                  alt="logo"
                  sx={{
                    width: "50px",
                    height: "auto",
                    p: 1,
                  }}
                ></Box>
                <Tooltip title="Copy to clipboard" arrow>
                  <Button
                    onClick={() => {
                      copyToClipBoard(formatParagraphForMarkdown(v?.answer));
                      // handleAnotherFunction(); // Call the second function here
                    }}
                    sx={{
                      minWidth: "unset",
                      padding: "4px",
                      width: "auto",
                      height: "auto",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ChatCopyIcon />
                  </Button>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  width: "100%",
                  paddingLeft: "25px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                {" "}
                <Typography
                  variant="body1"
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    fontFamily: theme?.typography?.fontFamily,
                    maxWidth: "90%",
                    fontSize: "15px",
                    overflowX: "hidden", // Prevents horizontal overflow
                    wordWrap: "break-word", // Ensures long words wrap to the next line
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {v?.answer}
                  {/* <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {formatParagraphForMarkdown(v?.answer)}
                  </ReactMarkdown> */}
                </Typography>
              </Box>
            </Box>
            {v?.sources?.map((val, i) => (
              <Box key={i} sx={{ padding: "0 10px " }}>
                <Accordion
                  expanded={expanded.includes(val.source + i + j)}
                  onChange={handleExpansion(val.source + i + j)}
                  sx={{
                    boxShadow: "none", // No shadow for a clean look
                    borderTop: "1px solid #E0E0E0", // Light border for each accordion
                    borderLeft: "1px solid #E0E0E0", // Light border for each accordion
                    borderRight: "1px solid #E0E0E0", // Light border for each accordion
                    borderBottom:
                      i === v?.sources.length - 1 ? "1px solid #E0E0E0" : "",
                    borderRadius: 0,
                    "&:first-of-type": {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    },
                    "&:last-of-type": {
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                    "&:before": {
                      display: "none", // Remove default before pseudo-element
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${val.source + i + j}-content`}
                    id={`panel${val.source + i + j}-header`}
                    sx={{
                      backgroundColor: expanded.includes(val.source + i + j)
                        ? "#F5F7FB" // Light background for expanded state
                        : "#FFFFFF", // White for collapsed state
                      color: theme?.palette?.primary?.placeholderColor,
                      // borderRadius: "4px",
                      "& .MuiSvgIcon-root": {
                        color: expanded.includes(val.source + i + j)
                          ? "#387CFF" // Blue icon when expanded
                          : "#A0A0A0", // Gray icon when collapsed
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500, // Slightly bold for emphasis
                        color: expanded.includes(val.source + i + j)
                          ? "#505058" // Blue text for expanded accordion
                          : "#505058", // Neutral gray for collapsed accordion
                        transition: "color 0.3s ease", // Smooth color transition
                      }}
                    >
                      {val.source}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: "#FFFFFF", // Subtle contrast for details section
                      borderTop: "1px solid #E0E0E0", // Divider between header and details
                      color: theme?.palette?.primary?.secondaryheading,
                    }}
                  >
                    {/* Source Info */}
                    <Box
                      sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 500,
                          color: "#387CFF", // Emphasized blue for label
                        }}
                      >
                        Source:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#505058", // Neutral gray for value
                          fontWeight: 400,
                        }}
                      >
                        {val.file}
                      </Typography>
                      {`${val?.id}` === downloadLoader ? (
                        <Box sx={{ marginLeft: "10px" }}>
                          <LoaderIcon
                            color={theme?.palette?.primary?.main}
                            width={20}
                            height={24}
                          />
                        </Box>
                      ) : (
                        <Typography
                          sx={{
                            cursor: "pointer",
                            marginLeft: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            handleFileAction(
                              `${chatValue.category}/${val?.id}`,
                              "download",
                              `${val?.id}`,
                              `${val?.title}`
                            );
                          }}
                        >
                          <GetAppIcon
                            sx={{ width: "20px", color: "#505058" }}
                          />
                        </Typography>
                      )}
                    </Box>

                    {/* Citation Info */}
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 500,
                          color: "#387CFF", // Emphasized blue for label
                        }}
                      >
                        Citation:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#505058", // Neutral gray for value
                          fontWeight: 400,
                        }}
                      >
                        {val?.citation}
                      </Typography>
                    </Box>

                    {/* Content */}
                    <Typography
                      sx={{
                        marginTop: "16px",
                        color: theme?.palette?.primary?.placeholderColor,
                      }}
                    >
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: val.content.replace(/\n/g, "<br />"),
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </Box>
        ))}

        {loader || (isLoading && data.length > 0) ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.primary?.placeholderColor,
                  // fontSize: "12px",
                  // fontWeight: "400",
                  fontFamily: theme?.typography?.fontFamily,
                  background: "#F5EEFF",
                  p: 1,
                  margin: "15px 0 15px 100px ",
                  borderRadius: "4px",

                  // maxWidth: "80%",
                  ...theme?.typography?.bodyText,
                }}
              >
                {formik?.values?.title}
              </Typography>
              <Typography
                sx={{
                  p: "7px",
                  backgroundColor: theme?.palette?.primary?.secondaryheading,
                  width: "38px",
                  height: "38px",
                  borderRadius: "50%",
                  color: "#2B2C40",
                  marginRight: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  ...theme?.typography?.inputPlaceHolder,
                }}
              >
                {`${user?.userData?.firstName?.charAt(
                  0
                )}${user?.userData?.lastName?.charAt(0)}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Box
                component="img"
                src="/images/logoD.png"
                alt="logo"
                sx={{
                  width: "55px",
                  height: "auto",
                  p: 1,
                }}
              ></Box>
              <ChatWaitingIcon />
            </Box>
          </Box>
        ) : (
          <>
            {/* Fallback when no data */}
            {data?.length == 0 && (
              <Box
                sx={{
                  display: "flex", // Enable flexbox
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                  flexDirection: "column",
                  width: "100%", // Full width of the container
                  height: "50vh", // Full viewport height
                  [theme.breakpoints.down("xs")]: {
                    height: "60vh", // Adjust for small screens if needed
                  },
                  [theme.breakpoints.down("md")]: {
                    height: "50vh", // Adjust for small screens if needed
                  },
                }}
              >
                {/* display: "flex", width: "100%", justifyContent: "center", flexDirection: "column", height: "100%"  */}
                <QuerieIcon />
              </Box>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          color: "lightgray",
          background: theme?.palette?.primary?.bodyColor,
          borderRadius: "1px",
          padding: "0 10px 10px 10px",
          marginTop: "20px",
        }}
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              bgcolor: theme?.palette?.background?.paper,
              borderRadius: "8px",
              // border: "1px solid #c3c4d0", // Light border similar to the input style
              padding: "7px 10px",
              border: "1px solid #387CFF",
            }}
          >
            <TextField
              error={formik.touched.title && Boolean(formik.errors?.title)}
              name="title"
              id="title"
              placeholder="Type your message here..."
              variant="standard"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={loader ? "" : formik.values.title}
              disabled={loader}
              helperText={formik.touched?.title && formik.errors?.title}
              multiline
              minRows={1}
              maxRows={4}
              sx={{
                flexGrow: 1,
                "& .MuiInputBase-input": {
                  color: theme?.palette?.text?.primary, // Input text color
                  fontSize: "14px",
                },
                "& .MuiInput-underline:before": {
                  borderBottom: "none", // Remove underline for inactive state
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none", // Remove underline for active state
                },
                "& textarea": {
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar
                  },
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: loader
                    ? theme?.palette?.text?.disabled
                    : theme?.palette?.text?.primary,
                },
                onKeyDown: (e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevent new line from being created
                    if (!loader && chatValue.category) {
                      formik.handleSubmit(); // Submit the form
                    }
                  }
                },
              }}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{
                marginLeft: "10px",
                backgroundColor:
                  loader || !chatValue.category ? "#E6E7F2" : "#387CFF",
                boxShadow: 0,
                color: "#ffffff",
                borderRadius: "50%", // Circular button
                p: 0,
                width: "40px",
                height: "40px",
                minWidth: "40px",
                minHeight: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#2962cc", // Slightly darker blue on hover
                },
                // "&.Mui-disabled": {
                //   backgroundColor: "#c3c4d0", // Disabled state color
                //   color: "#ffffff",
                // },
              }}
              disabled={loader || !chatValue.category}
            >
              {loader ? (
                <ChatLoaderIcon />
              ) : !chatValue.category ? (
                <ChatDisabledIcon />
              ) : (
                <SendIcon />
              )}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default QueriesForm;
