export const GeneratePleadingHeading = [
  {
    id: 1,
    title: "Statement of Material Facts",
  },
  // { id: 2, title: "  Single Assignment of Error" },
  // { id: 3, title: " Single Issue Legal Memorandums" },
  // { id: 4, title: "   Statement of Material Facts" },
  // {
  //   id: 5,
  //   title: " Written Reasons for Judgment",
  // },
];

export const allCategory = [
  { id: 0, title: "Client", values: "client" },
  { id: 1, title: "Person", values: "person" },
  { id: 2, title: "Medical Records", values: "medical" },
  { id: 3, title: "Exhibits", values: "exhibit" },
  { id: 4, title: "Discovery", values: "discovery" },
  { id: 5, title: "Depositions", values: "depositions" },
  // { id: 6, title: "Correspondence", values: "correspondence" },
  { id: 6, title: "Pleadings", values: "pleading" },
  // { id: 7, title: "Exhibit Listing", values: "exhibitlisting" },
];

export const allItem = [
  { id: 1, title: "Deposition of Adam Smith" },
  { id: 2, title: "Deposition of Professor Albert Dsouza" },
  { id: 3, title: "Deposition of Miss Karley Joe" },
  { id: 4, title: "Deposition of Tim Seitz" },
  { id: 5, title: "Deposition of Dr. Kelly Scrantz" },
  { id: 6, title: "Deposition of Miss Karley Joe" },
  { id: 6, title: "Deposition of Adam Smith" },
];
